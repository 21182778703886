@import "./style/font";
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  .layout {
    position: relative;
    padding-top: 123px;
    @media (max-width: 767px) {

      padding-top: 97px;
    }
  }
}