@import '../../style/colors';
.Footer {
  display: flex;
  margin-top: auto;
  background: $web-black;
  justify-content: center;
  > ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    @media (max-width: 767px){
      flex-direction: column;
      > li {
        margin-bottom: 8px;
        &.line {
        display: none;
        }
      }
    }
    > li {
      color: $web-white;
      display: flex;
      justify-content: center;
      text-wrap: nowrap;
      &.line {
        width: 2px;
        height: 35px;
        background: white;
        margin: 0 20px;
      }
      > a {
        text-decoration: none;
        color: $web-white;
        display: flex;
        align-items: center;
        > img {
          max-width: 16px;
          width: 100%;
          max-height: 16px;
          margin-right: 15px;
        }
      }
    }
  }
}