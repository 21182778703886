@import "../../style/font";
@import "../../style/colors";

.Home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $web-white;
    padding: 0 32px;
    > h1 {
      font-family: $font-main-title;
      font-size: 56px;
      text-align: center;
      margin-top: 100px;
      margin-bottom: 20px;
      color: black;
    }
    > p {
      max-width: 753px;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      font-size: 18px;
      font-style: normal;
      color: $web-black;
    }
    button {
      a {
        text-decoration: none;
        color: inherit;
        &:focus {
          outline: 0;
        }

      }
      cursor: pointer;
      width: 223px;
      height: 65px;
      border-radius: 18px;
      border: 0;
      background: black;
      color: $web-white;
      font-size: 20px;
      margin: 50px auto 75px;
    }
    div.apps {
      display: flex;
      justify-content: center;
      margin-bottom: 88px;
      flex-wrap: wrap;
      >img {
        margin: 7px;
        max-width: 61px;
        width: 100%;
      }
    }
  }
  > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 44px 0 47px;
    > h2 {
      font-family: $font-main-title;
      font-size: 48px;
      margin: 0;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 74px;
      max-width: 1365px;
      > div {
        margin: 0 22.5px 90px;
        transition: 250ms ease all;
        cursor: pointer;
        z-index: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        >div {
          display: flex;
          flex: 1;
          > img {
            max-width: 330px;
            width: 100%;
          }
        }
        span {
          position: absolute;
          bottom: -10px;
        }
      }
    }
  }
}