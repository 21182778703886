@import '../../style/colors';

.Navbar {
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  padding: 29px 64px;
  background: $web-black;
  box-shadow: 0px -8px 20px 11px #00000075;
  @media (max-width: 767px) {
    padding: 29px 30px;
  }
  > div.mobile-menu {
    position: absolute;
    display: flex;
    top: 97px;
    right: 0;
    z-index: 2;
    height: 100vh;
    width: 100%;
    background: linear-gradient($web-black 75%, $web-black 25%);
  }
  > div.hamburger {
    display: none;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 40px;
      height: 40px;
      > div {
        transition: 200ms ease-in-out transform;
        width: 60%;
        height: 1px;
        background: $web-white;
      }
      &.open {
        justify-content: center;
        > div {
          transition: 200ms ease-in-out transform;
          &:first-of-type {
            position: relative;
            top: 1px;
            -webkit-transform:rotate(45deg);
            -moz-transform:rotate(45deg);
            transform:rotate(45deg);

          }
          &:nth-of-type(3) {
            -webkit-transform:rotate(-45deg);
            -moz-transform:rotate(-45deg);
            transform:rotate(-45deg);
          }
        }
        > div:nth-of-type(2) {
          display: none;
        }
      }
    }
  }
  > div:not(.hamburger) {
    display: flex;
    > img {
      width: 52px;
      height: 40px;
    }
  }
  ul {
    list-style: none;
    display: flex;
    @media (max-width: 767px) {
      &.desktop {display: none;}
    }
    > li {
      position: relative;
      color: $web-white;
      cursor: pointer;
      font-size: 24px;
      font-weight: 300;
      &.active {
        font-weight: 500;
        span {
          position: absolute;
          bottom: -9px;
          display: flex;
          width: 24px;
          height: 6px;
          left: calc(50% - 12px);
          background: white;
        }
      }
      &:not(:first-of-type) {
        margin-left: 52px;
      }
    }
    &.mobile {
      flex-direction: column;
      > li {
        margin: 0 0 20px;
        padding-left: 12px;
        > span {
          left: 12px;
        }
      }
    }
  }
}