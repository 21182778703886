@import "../../style/font";
@import "../../style/colors";

.project {
  max-width: 1360px;
  display: flex;
  margin: 0 auto ;
  flex-direction: column;
  padding: 0 126px 60px;
  background: #ffffff;
  @media (max-width: 1365px) {
    padding: 0 8% 40px;
  }
  > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px 0 74px;
    > h1 {
      font-family: $font-main-title;
      font-size: 56px;
      margin: 0;
    }

    > p {
      font-family: $font-poppins;
      font-size: 18px;
      margin: 0;
      max-width: 691px;
      text-align: center;
      > span {
        font-weight: 700;
      }
    }
  }

  > section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 95px;
    width: 100%;
    h3.title {
      font-family: $font-main-title;
      font-size: 38px;
      margin: 0 0 17px;
    }
    &.about {

      > div:first-of-type {
        display: flex;
        flex-direction: column;
        max-width: 385px;
        width: 100%;
      }


      > div > p {
        font-family: $font-poppins;
        font-size: 18px;
        font-weight: 300;
        margin: 0;
      }

      > div > img {
        width: 100%;
      }
      @media (max-width: 1365px) {
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 45px;
        > div:first-of-type {
          max-width: 90%;
          margin-bottom: 40px;
          > h3, p {
            text-align: center;
          }
        }
      }
    }
    &.design {
      flex-direction: column;
      flex-wrap: wrap;
      > div {
        display: flex;
        &.images {
          margin-top: 16px;
        }
        > img {
          max-width: 772px;
          width: 100%;
          max-height: 536px;
          &.c1-mobile {display: none}
          &.text {
            margin-right: 183px;
          }
          &.images {
            padding-right: 125px;
          }
        }
        @media (max-width: 1365px) {
          flex-wrap: wrap;
          flex-direction: column-reverse;
          >img {
            &.c1 {display: none}
            &.c1-mobile {
              display: unset;
              margin: 0 auto 25px;
              max-width: 80%;
            }
            &.text {
              margin-right: 0;
            }
            &.images {
              padding-right: 0;
            }
          }
        }
      }
      &.jh {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        padding-top: 60px;
        @media (max-width: 1442px) {
          flex-wrap: wrap-reverse;
        }
        .first {
          img {
            width: 562px;
          }
          margin-right: 47px;
        }

        .second {

          img {
            width: 109px;
          }
          margin-right: 51px;
        }

        .third {
          display: flex;
          flex-direction: column;
          img {
            margin-left: 57px;
            width: 364px;
          }
          .text {
            max-width: 385px;
            padding-right: 36px;
          }
          @media (max-width: 1442px) {
            flex-direction: column-reverse;
            > div {
              text-align: center;
            }
            img {
              margin-left: 0;
              margin-top: 29px;
              max-width: 364px;
              width: 100%;
            }
            .text {
              text-align: center;
              max-width: 560px;
              padding-right: 0;
            }

          }
        }
        @media (max-width: 1442px) {
          .nowrap {
            margin-top: 36px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            >div {
             margin: 0;
              &.first {
                margin-right: 23px;
              }
             img {
               width: 100%;
             }
            }
          }
        }
      }
      &.techdesk {
        flex-direction: row;
        justify-content: center;
        flex-wrap: unset;
        > div.images {
          margin-right: 140px;
          > img{
            &:first-of-type {
              margin-right: 135px;
            }
          }
        }
        > div.text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 385px;
        }
        @media (max-width: 1365px) {
          position: relative;
          > div.images {
            margin-right: 20px;
            > img {
              &:first-of-type {
                margin-right: 0;
              }
              &.c1-mobile {
                position: absolute;
                width: 50%;
                right: 20px;
                bottom: 0;
                margin-bottom: 0;
              }
            }
          }
        }
        @media (max-width: 768px) {
          flex-direction: column-reverse;
          align-items: baseline;
          > div.text {
            max-width: 530px;
            z-index: 2;
          }
          > div.images {
            flex-direction: row;
            margin: 0;
            width: 100%;
            > img {
              z-index: 1;
              &:first-of-type {
                margin-left: auto;
                position: relative;
                right: 0;
                margin-top: 20px;
                margin-bottom: 20px;
              }
              &.c1-mobile {
                display: unset;
                position: absolute;
                right: 0;
                top: 7px;
                width: 40%;
              }
            }
          }
        }
      }
    }
    &.description:not(.fibon) {
      margin-top: 140px;
      > div {
        position: relative;
        &.text {
          max-width: 486px;
        }
        > p {
          font-family: $font-poppins;
          font-size: 18px;
          font-weight: 300;
          margin: 0;
          background: #ffffff;
        }
        > img {
          max-width: 858px;
          width: 100%;
        }
      }
      @media (max-width: 1365px) {
        flex-wrap: wrap;
        >div {
          &.text {
            margin-right: auto;
          }
          &.image {
            margin-left: auto;
          }

        }
      }
      &.techdesk {
        @media (max-width: 767px) {
          margin-top: 40px;
        }
        > .image {
          margin: 0;
          > img {
            @media (max-width: 767px) {
              transform: scale(1.5);
            }
          }
        }
      }
    }
    &.description.fibon {
      margin-top: 140px;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      > div {
        &.text {
          margin-bottom: 216px;
          max-width: 486px;

        }

        > p {
          font-family: $font-poppins;
          font-size: 18px;
          font-weight: 300;
          margin: 0;
          background: #ffffff;
        }

        > img {
          position: absolute;
          bottom: 67px;
          right: 0;
          max-width: 833px;
          width: 100%;
          @media (max-width: 768px) {
            bottom: 122px;
          }
        }
      }

      @media (max-width: 1365px) {
        > div {
          > img {
            width: 50%;
          }
        }
      }
      @media (max-width: 998px) {
        margin-top: 60px;
        > div {
          > img {
            width: 65%;
          }
        }
      }

      @media (max-width: 465px) {
        > div {
          > img {
            width: 100%;
          }
        }
      }
    }
    &.examples {
      padding-top: 41px;
      > div:first-child {
        margin-right: 109px;
        >img {
          position: relative;
          top: -13px
        }
      }
      @media (max-width: 1442px) {
        margin-top: 23px;
        padding-top: 0;
        flex-direction: column-reverse;
        > div {
         > img {
           width: 100%;
         }
          &:first-child {
            margin-right: 0;
            margin-top: 64px;
            display: flex;
            justify-content: center;
            > img {
              width: 75%;
              top: 0;
            }
          }
        }
      }
    }
    &.fcover {
      div {

      display: flex;
      justify-content: center;
      img {
        width: 100%;
        @media (max-width: 1442px) {
          width: 125%;
        }
      }
      }
    }
  }
}

#design-system {
  display: flex;
  flex-direction: column;
  > div.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 35px;
    h3 {
      font-family: $font-main-title;
      font-size: 38px;
      margin: 0 0 58px;
    }
    img {
      width: 100%;
      max-width: 456px;
    }
  }
  .main {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap-reverse;
    img {
      max-width: 100%;
      margin-bottom: 20px;
    }
    .dsl1, .dsjh1 {
      margin-top: 111px;
      margin-right: 65px;
    }

    .dsjh1, .dsjh2 {
      max-width: 562px;
    }

  }
  @media (max-width: 14411px) {
    .main {

    .dsjh1, .dsjh2 {
      max-width: 400px;
    }
    }
  }
  @media (max-width: 1001px) {
    > div.header {
      align-items: center;
      margin-bottom: 45px;
      h3 {
        margin: 0 0 28px;
      }
      > img {
        max-width: 260px;
      }
    }
    .main {
      justify-content: center;
      @media (max-width: 600px) {
        img {
          max-width: 220px;
        }
      }
      .dsl1, .dsjh1 {
        margin: 22px 0 0 68px;
      }
      .dsl2, .dsjh2 {
        margin: 0 68px 0 0;
      }

      @media (max-width: 480px) {
        .dsjh1, .dsjh2 {
          max-width: 220px;
        }
      }
    }
  }
}

#jhtextsectoin {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  p {
    margin: 152px 0 126px;
    max-width: 814px;
    font-family: $font-poppins;
    font-size: 18px;
    font-weight: 300;
    @media (max-width: 768px) {
      margin: 80px 0 60px;
      text-align: center;
    }
  }

  img {
    position: absolute;
    right: 100px;
    max-width: 518px;
    @media (max-width: 768px) {
      right: -50px;
      max-width: 360px;
    }
  }
}